import React, { useState } from 'react';
import { Form, Button, Container, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { registerAuth } from '../reducers/AuthSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';


function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const response = dispatch(registerAuth(formData));
        if (response) {
            toast.success('Registration successful!');
            navigate('/login');
        }
    };

    return (
        <Container fluid className="auth-container">
            <Row className="h-100">
                <Col className="left w-50 d-flex justify-content-center align-items-center">
                    <div className="content">
                        <h2 className="text-center text-white">Register</h2>
                    </div>
                </Col>
                <Col className="right w-50 d-flex justify-content-center align-items-center">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="my-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                placeholder="Enter Username..."
                            />
                        </Form.Group>
                        <Form.Group className="my-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter Email..."
                            />
                        </Form.Group>
                        <Form.Group className="my-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Enter Password..."
                            />
                        </Form.Group>
                        <Button type="submit" variant="dark" className="my-3">
                            Register
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Register;
