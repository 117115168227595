import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Service from '../api/ApiRoutes';

const initialState = {
    auth: null,
    isLoading: false,
    error: null,
    isAuthenticated: false,
};

// Register User (auth)
export const registerAuth = createAsyncThunk(
    'auth/register',
    async (authData, { rejectWithValue }) => {
        try {
            const response = await Service.register(authData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : 'Something went wrong');
        }
    }
);

// Login User (auth)
export const loginAuth = createAsyncThunk(
    'auth/login',
    async (loginData, { rejectWithValue }) => {
        try {
            const response = await Service.login(loginData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : 'Something went wrong');
        }
    }
);

// Get Auth by ID
export const getAuthById = createAsyncThunk(
    'auth/getAuthById',
    async (id, { rejectWithValue, getState }) => {
        try {
            const state = getState();
            const token = state.auth.auth?.token;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await Service.getAuthById(id, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : 'Something went wrong');
        }
    }
);

// Update Auth by ID
export const updateAuth = createAsyncThunk(
    'auth/updateAuth',
    async ({ id, authData }, { rejectWithValue, getState }) => {
        try {
            const state = getState();
            const token = state.auth.auth?.token;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await Service.updateAuth(id, authData, config);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : 'Something went wrong');
        }
    }
);

// Delete Auth by ID
export const deleteAuth = createAsyncThunk(
    'auth/deleteAuth',
    async (id, { rejectWithValue, getState }) => {
        try {
            const state = getState();
            const token = state.auth.auth?.token;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            await Service.deleteAuth(id, config);
            return id;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : 'Something went wrong');
        }
    }
);

// Auth Slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.auth = null;
            state.isAuthenticated = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerAuth.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(registerAuth.fulfilled, (state, action) => {
                state.isLoading = false;
                state.auth = action.payload;
                state.isAuthenticated = true;
                state.error = null;
            })
            .addCase(registerAuth.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })

            .addCase(loginAuth.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(loginAuth.fulfilled, (state, action) => {
                state.isLoading = false;
                state.auth = action.payload;
                state.isAuthenticated = true;
                state.error = null;
            })
            .addCase(loginAuth.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })

            .addCase(getAuthById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAuthById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.auth = action.payload;
                state.error = null;
            })
            .addCase(getAuthById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })

            .addCase(updateAuth.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateAuth.fulfilled, (state, action) => {
                state.isLoading = false;
                state.auth = action.payload;
                state.error = null;
            })
            .addCase(updateAuth.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })

            .addCase(deleteAuth.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteAuth.fulfilled, (state, action) => {
                state.isLoading = false;
                state.auth = null;
                state.isAuthenticated = false;
                state.error = null;
            })
            .addCase(deleteAuth.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
