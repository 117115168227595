import React from 'react'
import Navbar from "../components/Navbar"
import MainContent from '../components/MainContent'
import Sidebar from "../components/Sidebar"
function Home() {
    return (
        <>
            <Navbar />
            <div style={{ display: 'flex' }}>
                <Sidebar />
                <MainContent />
            </div>
        </>
    )
}

export default Home