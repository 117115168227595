import React, { useState, useEffect, useRef } from 'react';
import { MdAccountCircle } from "react-icons/md";
import { Link } from "react-router-dom";
import SpeedIcon from '@mui/icons-material/Speed';
import SchoolIcon from "@mui/icons-material/School";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import GroupIcon from "@mui/icons-material/Group";
import WorkIcon from "@mui/icons-material/Work";
import PaymentIcon from "@mui/icons-material/Payment";
import BuildIcon from "@mui/icons-material/Build";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import '../assets/css/Navbar.css';
import { logout } from "../api/ProtectedRoute";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('/');
  const [openDialog, setOpenDialog] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  const handleMenuToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLinkClick = (path) => {
    setActiveLink(path);
    setIsSidebarOpen(false);
  };

  const handleLogoutClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmLogout = () => {
    setOpenDialog(false);
    logout();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !event.target.closest('.menu-icon')) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="border-bottom py-2 px-2">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col d-flex align-items-center justify-content-between">
              <div className="navbar-link mx-2 d-flex align-items-center gap-2">
                <MenuIcon size={40} className="menu-icon text-white" onClick={handleMenuToggle} />
                <Link to="/" className="text-decoration-none">
                  <h3 className="mb-0 text-white">AK Boys Hostel</h3>
                </Link>
              </div>
              <div className="col col-md-4" style={{ textAlign: "right" }}>
                <Link to={"/profile"}> <MdAccountCircle size={40} className="profile-icon" /></Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div ref={sidebarRef} className={`sidebar1 ${isSidebarOpen ? "open" : ""}`}>
        <ul className="list-unstyled">
          <li className={activeLink === "/" ? "active" : ""} onClick={() => handleLinkClick("/")}>
            <Link to="/">
              <SpeedIcon style={{ color: "blue" }} /> Dashboard
            </Link>
          </li>
          <li className={activeLink === "/students" ? "active" : ""} onClick={() => handleLinkClick("/students")}>
            <Link to="/students">
              <SchoolIcon style={{ color: "green" }} /> Students
            </Link>
          </li>
          <li className={activeLink === "/rooms" ? "active" : ""} onClick={() => handleLinkClick("/rooms")}>
            <Link to="/rooms">
              <MeetingRoomIcon style={{ color: "purple" }} /> Rooms
            </Link>
          </li>
          <li className={activeLink === "/bookings" ? "active" : ""} onClick={() => handleLinkClick("/bookings")}>
            <Link to="/bookings">
              <BookOnlineIcon style={{ color: "orange" }} /> Booking
            </Link>
          </li>
          <li className={activeLink === "/staff" ? "active" : ""} onClick={() => handleLinkClick("/staff")}>
            <Link to="/staff">
              <GroupIcon style={{ color: "teal" }} /> Staff
            </Link>
          </li>
          <li className={activeLink === "/workers" ? "active" : ""} onClick={() => handleLinkClick("/workers")}>
            <Link to="/workers">
              <WorkIcon style={{ color: "red" }} /> Workers
            </Link>
          </li>
          <li className={activeLink === "/payments" ? "active" : ""} onClick={() => handleLinkClick("/payments")}>
            <Link to="/payments">
              <PaymentIcon style={{ color: "gold" }} /> Payments
            </Link>
          </li>
          <li className={activeLink === "/maintenances" ? "active" : ""} onClick={() => handleLinkClick("/maintenances")}>
            <Link to="/maintenances">
              <BuildIcon style={{ color: "brown" }} /> Maintenance
            </Link>
          </li>
          <li className={activeLink === "/settings" ? "active" : ""} onClick={() => handleLinkClick("/settings")}>
            <Link to="/settings">
              <SettingsIcon style={{ color: "gray" }} /> Settings
            </Link>
          </li>
          <li onClick={handleLogoutClick}>
            <Link to="#">
              <LogoutIcon style={{ color: "black" }} /> Logout
            </Link>
          </li>
        </ul>
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to Log Out?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmLogout} color="secondary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;
