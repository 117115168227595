import React from 'react'

import { FaTrashCan, FaCheck, FaXmark } from 'react-icons/fa6'
import { MdOutlineKeyboardAlt, MdOutlineBorderColor } from "react-icons/md"

import { Link } from 'react-router-dom'
const Maintenance = () => {
  (
    <>
      <div class="table-header d-flex justify-content-between mt-3 p-3">
        <div class="total-count">
          <h6>Total Users (12)</h6>
        </div>
        <div class="add-btn">
          <Link to={"/add-students"}><button class="btn btn-primary text-white">Add Students</button></Link>
        </div>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Sr #</th>
            <th scope="col">Student Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Room No</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="pagination-1 d-flex justify-content-center gap-3 align-items-center">
        <button className="pagination-btn">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <div className="page-info">
          Page 1 of 3
        </div>
        <button className="pagination-btn">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>
    </>
  )
}

export default Maintenance
