import React from 'react'
import notFoundImg from '../assets/images/notfound.jpg'
import '../assets/css/NotFound.css'

const NotFound = () => {
    return (
        <>
            <div class="not-found-bg">
                <img src={notFoundImg} alt='not-found'></img>
            </div>
        </>
    )
}

export default NotFound
