import React, { useState } from 'react'

function AddPayment() {
  return (
    <div class="container mt-5">
      <h3 class="text-center mb-4">Hostel Payment/Salary Form</h3>
      <form>
        <div class="row mb-3">
          <div class="col-md-4">
            <label for="employeeName" class="form-label">Employee Name</label>
            <input type="text" class="form-control" id="employeeName" placeholder="Enter name" required />
          </div>
          <div class="col-md-4">
            <label for="designation" class="form-label">Designation</label>
            <input type="text" class="form-control" id="designation" placeholder="Enter designation" required />
          </div>
          <div class="col-md-4">
            <label for="paymentType" class="form-label">Payment Type</label>
            <select class="form-select" id="paymentType" required>
              <option value="">Select Payment Type</option>
              <option value="salary">Salary</option>
              <option value="bonus">Bonus</option>
              <option value="advance">Advance</option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-4">
            <label for="amount" class="form-label">Amount</label>
            <input type="number" class="form-control" id="amount" placeholder="Enter amount" required />
          </div>
          <div class="col-md-4">
            <label for="paymentDate" class="form-label">Payment Date</label>
            <input type="date" class="form-control" id="paymentDate" required />
          </div>
          <div class="col-md-4">
            <label for="paymentMode" class="form-label">Payment Mode</label>
            <select class="form-select" id="paymentMode" required>
              <option value="">Select Payment Mode</option>
              <option value="cash">Cash</option>
              <option value="bank_transfer">Bank Transfer</option>
              <option value="cheque">Cheque</option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-4">
            <label for="comments" class="form-label">Comments</label>
            <textarea class="form-control" id="comments" placeholder="Enter additional details" rows="3"></textarea>
          </div>
          <div class="col-md-8 d-flex align-items-end">
            <button type="submit" class="btn btn-primary w-100">Submit Payment</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddPayment