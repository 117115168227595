import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllStudents } from '../reducers/StudentSlice';
import { fetchAllRooms } from '../reducers/RoomSlice';
import { createBooking } from '../reducers/BookingSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';



function AddBookings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [selectedRoomId, setSelectedRoomId] = useState('');
  const [advancedPayment, setAdvancedPayment] = useState('');
  const [totalPayment, setTotalPayment] = useState('');
  const [remainingAmount, setRemainingAmount] = useState('');
  const { students, status: studentsStatus } = useSelector((state) => state.students);
  const { rooms, status: roomsStatus } = useSelector((state) => state.rooms);

  useEffect(() => {
    if (studentsStatus === 'idle') {
      dispatch(fetchAllStudents());
    }
    if (roomsStatus === 'idle') {
      dispatch(fetchAllRooms());
    }
  }, [dispatch, studentsStatus, roomsStatus]);

  useEffect(() => {
    if (selectedRoomId) {
      const room = rooms.find((room) => room._id === selectedRoomId);
      const defaultTotalPayment = room ? room.monthlyRent : 0;
      setTotalPayment(defaultTotalPayment);
      setRemainingAmount(defaultTotalPayment - (parseFloat(advancedPayment) || 0));
    }
  }, [selectedRoomId, advancedPayment, rooms]);

  const handleStudentChange = (event) => {
    setSelectedStudentId(event.target.value);
  };

  const handleRoomChange = (event) => {
    setSelectedRoomId(event.target.value);
  };

  const handleAdvancedPaymentChange = (event) => {
    let payment = parseFloat(event.target.value) || 0;
    if (payment < 0) {
      payment = 0;
      alert("Advanced payment cannot be less than 0. Setting it to 0.");
    }
    setAdvancedPayment(payment);
  };

  const handleTotalPaymentChange = () => {
    const room = rooms.find((room) => room._id === selectedRoomId);
    const totalMonths = parseInt(document.getElementById('totalMonths').value, 10);

    const total = (parseFloat(room?.monthlyRent) || 0) * totalMonths;

    setTotalPayment(total);
    setRemainingAmount(total - (parseFloat(advancedPayment) || 0));
  };

  const selectedStudent = students.find((student) => student._id === selectedStudentId);
  const selectedRoom = rooms.find((room) => room._id === selectedRoomId);

  const handleSubmit = (e) => {
    e.preventDefault();
    const bookingData = {
      selectedStudent,
      selectedRoom,
      advancedPayment,
      totalPayment,
      remainingAmount,
      bookingDate: e.target.bookingDate.value,
      startDate: e.target.startDate.value,
      endDate: e.target.endDate.value,
      totalMonths: e.target.totalMonths.value,
      paymentMode: e.target.paymentMode.value,
      paymentStatus: e.target.paymentStatus.value,
      notes: e.target.notes.value,
    };

    const response = dispatch(createBooking(bookingData));
    if (response) {
      toast.success('Booking has been added!');
      navigate('/bookings');
    }
  };

  return (
    <div className="container mt-5 text-white">
      <h2 className="text-center mb-4">Booking Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="studentName" className="form-label">Student Name</label>
            <select
              className="form-select"
              id="studentName"
              value={selectedStudentId}
              onChange={handleStudentChange}
              required
            >
              <option value="" disabled>Select student</option>
              {students?.map((student) => (
                <option key={student._id} value={student._id}>
                  {student.studentName}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="studentId" className="form-label">Student ID</label>
            <input
              type="text"
              className="form-control"
              id="studentId"
              value={selectedStudent?._id || ''}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="roomNumber" className="form-label">Room Number</label>
            <select
              className="form-select"
              id="roomNumber"
              value={selectedRoomId}
              onChange={handleRoomChange}
              required
            >
              <option value="" disabled>Select a room</option>
              {rooms.map((room) => (
                <option key={room._id} value={room._id}>
                  {room.roomNumber}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="roomType" className="form-label">Room Type</label>
            <input
              type="text"
              className="form-control"
              id="roomType"
              value={selectedRoom?.roomType || ''}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="roomPrice" className="form-label">Room Price/Month</label>
            <input
              type="text"
              className="form-control"
              id="roomPrice"
              value={selectedRoom?.monthlyRent || ''}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="bookingDate" className="form-label">Booking Date</label>
            <input
              type="date"
              className="form-control"
              id="bookingDate"
              required
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="startDate" className="form-label">Start Date</label>
            <input
              type="date"
              className="form-control"
              id="startDate"
              required
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="endDate" className="form-label">End Date</label>
            <input
              type="date"
              className="form-control"
              id="endDate"
              required
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="totalMonths" className="form-label">No. of Months</label>
            <select
              className="form-select"
              id="totalMonths"
              value={totalPayment / (selectedRoom?.monthlyRent || 1)}
              onChange={handleTotalPaymentChange}
              required
            >
              <option value="" disabled>Select No. of months</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="advancedPayment" className="form-label">Advanced Payment</label>
            <input
              type="number"
              className="form-control"
              id="advancedPayment"
              value={advancedPayment}
              onChange={handleAdvancedPaymentChange}
              onBlur={handleTotalPaymentChange}
              required
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="remainingAmount" className="form-label">Remaining Amount</label>
            <input
              type="text"
              className="form-control"
              id="remainingAmount"
              value={remainingAmount}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="totalPayment" className="form-label">Total Amount</label>
            <input
              type="text"
              className="form-control"
              id="totalPayment"
              value={totalPayment}
              readOnly
            />
          </div>

        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="paymentMode" className="form-label">Payment Mode</label>
            <select
              className="form-select"
              id="paymentMode"
              required
            >
              <option value="" disabled>Select payment method</option>
              <option value="cash">Cash</option>
              <option value="bank_transfer">Bank Transfer</option>
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="paymentStatus" className="form-label">Payment Status</label>
            <select
              className="form-select"
              id="paymentStatus"
              required
            >
              <option value="" disabled>Select payment status</option>
              <option value="paid">Paid</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div className="col-md-12">
            <label htmlFor="notes" className="form-label">Notes</label>
            <textarea
              className="form-control"
              id="notes"
              rows="3"
            ></textarea>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <button type="submit" className="btn btn-primary text-white">Submit Booking</button>
        </div>
      </form>
    </div>
  );
}

export default AddBookings;
