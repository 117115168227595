import React from 'react'
import { Button, Row, Col, Table, Container } from 'react-bootstrap'
import { toast } from "react-toastify"
import { FaTrashCan, FaCheck, FaXmark } from 'react-icons/fa6'
import { MdOutlineKeyboardAlt, MdOutlineBorderColor, MdAddCircle } from "react-icons/md"
import Loader from '../components/Loader'
import Message from '../components/Message'
import { Link } from 'react-router-dom'
const Staff = () => {
  return (
    <>
      <div className="table-header d-flex justify-content-between mt-3 p-3">
        <div className="total-count">
          <h6>Total Staff (12)</h6>
        </div>
        <div className="add-btn">
          <Link to={"/add-staff"}><button className="btn btn-primary text-white">Add Staff</button></Link>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Sr #</th>
            <th scope="col">Room No</th>
            <th scope="col">Floor No</th>
            <th scope="col">Total Capacity</th>
            <th scope="col">Room Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
          <tr>
            <th scope="row">1</th>
            <td>Aqib</td>
            <td>aqib28000@gmail.com</td>
            <td>03278551518</td>
            <td>Room 12</td>
            <td className='action-flex'>
              <Link to={'/view-student'}><MdOutlineKeyboardAlt className='keyboard' /></Link>
              <Link to={'/edit-student'}><MdOutlineBorderColor className='edit' /></Link>
              <Link to={'/delete-student'}><FaTrashCan className='delete' /></Link>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="pagination-1 d-flex justify-content-center gap-3 align-items-center">
        <button className="pagination-btn">
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <div className="page-info">
          Page 1 of 3
        </div>
        <button className="pagination-btn">
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>
    </>
  )
}

export default Staff
