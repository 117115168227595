import React from 'react'
import Navbar from "../components/Navbar"
import {Row,Col}  from "react-bootstrap"
import {MdOutlineBed,MdOutlineFactCheck,MdPersonOutline,MdOutlinePaid} from "react-icons/md"
const Dashboard = () => {
  return (
    <div className='container-fluid dashboard'>
      <Row className="my-4">
      <h6>Overview</h6>
        <Col  md={3}   className="dash-boxes" >
          <div className="content border rounded d-flex justify-content-between p-4 bg-primary">
            <div className="text-box">
          <h6>Total Income</h6> 
           <span>21,232</span>
            </div>
            <div className="icon-box d-flex align-items-center">
             <MdOutlinePaid size={80}/>
            </div>
          </div>
        </Col>
        <Col   md={3} className="dash-boxes ">
          <div className="content border rounded  d-flex justify-content-between p-4 bg-warning">
          <div className="text-box">
          <h6>Total Users</h6> 
           <span>21,232</span>
            </div>
            <div className="icon-box d-flex align-items-center">
            <MdPersonOutline size={80}/>
            </div>
          </div>
        </Col>
        <Col  md={3} className=" dash-boxes">
          <div className="content border rounded  d-flex justify-content-between p-4 bg-success">
          <div className="text-box">
          <h6>Total Rooms</h6> 
           <span>21,232</span>
            </div>
            <div className="icon-box d-flex align-items-center">
            {/* <FaBed size={100}/> */}
            <MdOutlineBed size={80}/>
            </div>
          </div>
        </Col>
        <Col  md={3} className=" dash-boxes">
          <div className="content border rounded d-flex justify-content-between p-4 bg-secondary">
          <div className="text-box">
          <h6>Total Bookings</h6> 
           <span>21,232</span>
            </div>
            <div className="icon-box d-flex align-items-center">
             <MdOutlineFactCheck size={80}/>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="row graph">
      <Col  md={8} className="graph-box">
       <div className="graph1 border rounded">

       </div>
      </Col>
      <Col  md={4} className=" graph-box">
       <div className="graph2 border rounded">

       </div>
      </Col>
      </Row>
    </div>
  )
}

export default Dashboard
