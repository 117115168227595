import React, { useState } from 'react'
function AddMaintenance() {

  return (
    <>
      <div class="container mt-5">
        <h2 class="mb-4">Hostel Maintenance Form</h2>
        <form>
          <div class="row mb-3">
            <div class="col-md-4">
              <label for="maintenanceType" class="form-label">Maintenance Type</label>
              <select id="maintenanceType" class="form-select" required>
                <option value="" disabled selected>Select type</option>
                <option value="plumbing">Plumbing</option>
                <option value="electrical">Electrical</option>
                <option value="cleaning">Cleaning</option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="roomNumber" class="form-label">Room/Area Number</label>
              <input type="text" id="roomNumber" class="form-control" placeholder="Enter room or area" required />
            </div>
            <div class="col-md-4">
              <label for="priority" class="form-label">Priority</label>
              <select id="priority" class="form-select" required>
                <option value="" disabled selected>Select priority</option>
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-4">
              <label for="reportedBy" class="form-label">Reported By</label>
              <input type="text" id="reportedBy" class="form-control" placeholder="Name of reporter" required />
            </div>
            <div class="col-md-4">
              <label for="dateReported" class="form-label">Date Reported</label>
              <input type="date" id="dateReported" class="form-control" required />
            </div>
            <div class="col-md-4">
              <label for="estimatedCost" class="form-label">Estimated Cost</label>
              <input type="number" id="estimatedCost" class="form-control" placeholder="Enter amount" required />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-4">
              <label for="status" class="form-label">Status</label>
              <select id="status" class="form-select" required>
                <option value="" disabled selected>Select status</option>
                <option value="pending">Pending</option>
                <option value="in-progress">In Progress</option>
                <option value="completed">Completed</option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="assignedTo" class="form-label">Assigned To</label>
              <input type="text" id="assignedTo" class="form-control" placeholder="Name of assignee" required />
            </div>
            <div class="col-md-4">
              <label for="comments" class="form-label">Comments</label>
              <textarea id="comments" class="form-control" rows="1" placeholder="Additional notes"></textarea>
            </div>
          </div>

          <div class="text-end">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddMaintenance