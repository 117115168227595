import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllStudents } from '../reducers/StudentSlice';
import { fetchAllRooms } from '../reducers/RoomSlice';
import { useNavigate, useParams } from 'react-router';
import { getBookingById, updateBooking } from '../reducers/BookingSlice';

function UpdateBookings() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [selectedRoomId, setSelectedRoomId] = useState('');
  const [advancedPayment, setAdvancedPayment] = useState('');
  const [totalPayment, setTotalPayment] = useState('');
  const [remainingAmount, setRemainingAmount] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [notes, setNotes] = useState('');

  const { students, status: studentsStatus } = useSelector((state) => state.students);
  const { rooms, status: roomsStatus } = useSelector((state) => state.rooms);
  const { booking } = useSelector((state) => state.bookings);

  useEffect(() => {
    if (studentsStatus === 'idle') {
      dispatch(fetchAllStudents());
    }
    if (roomsStatus === 'idle') {
      dispatch(fetchAllRooms());
    }
  }, [dispatch, studentsStatus, roomsStatus]);

  useEffect(() => {
    if (id) {
      dispatch(getBookingById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (booking) {
      setSelectedStudentId(booking.studentId);
      setSelectedRoomId(booking.roomId);
      setAdvancedPayment(booking.advancedPayment);
      setTotalPayment(booking.totalPayment);
      setRemainingAmount(booking.remainingAmount);
      setPaymentMode(booking.paymentMode);
      setPaymentStatus(booking.paymentStatus);
      setNotes(booking.notes);
    }
  }, [booking]);

  useEffect(() => {
    if (selectedRoomId) {
      const room = rooms.find((room) => room._id === selectedRoomId);
      const defaultTotalPayment = room ? room.monthlyRent : 0;
      setTotalPayment(defaultTotalPayment);
      setRemainingAmount(defaultTotalPayment - (parseFloat(advancedPayment) || 0));
    }
  }, [selectedRoomId, advancedPayment, rooms]);

  const handleStudentChange = (event) => {
    setSelectedStudentId(event.target.value);
  };

  const handleRoomChange = (event) => {
    setSelectedRoomId(event.target.value);
  };

  const handleAdvancedPaymentChange = (event) => {
    let payment = parseFloat(event.target.value) || 0;
    if (payment < 0) {
      payment = 0;
      alert("Advanced payment cannot be less than 0. Setting it to 0.");
    }
    setAdvancedPayment(payment);
  };

  const handleTotalPaymentChange = () => {
    const room = rooms.find((room) => room._id === selectedRoomId);
    const totalMonths = parseInt(document.getElementById('totalMonths').value, 10);

    const total = (parseFloat(room?.monthlyRent) || 0) * totalMonths;

    setTotalPayment(total);
    setRemainingAmount(total - (parseFloat(advancedPayment) || 0));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedBooking = {
      studentId: selectedStudentId,
      roomId: selectedRoomId,
      advancedPayment,
      totalPayment,
      remainingAmount,
      paymentMode,
      paymentStatus,
      notes,
    };

    dispatch(updateBooking({ id, updatedBooking }));
    navigate(`/bookings/${id}`);
  };

  const selectedStudent = students.find((student) => student._id === selectedStudentId);
  const selectedRoom = rooms.find((room) => room._id === selectedRoomId);

  return (
    <div className="container mt-5 text-white">
      <h2 className="text-center mb-4">Update Booking</h2>
      <form onSubmit={handleSubmit}>
        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="studentName" className="form-label">Student Name</label>
            <select
              className="form-select"
              id="studentName"
              value={selectedStudentId}
              onChange={handleStudentChange}
              required
            >
              <option value="" disabled>Select student</option>
              {students?.map((student) => (
                <option key={student._id} value={student._id}>
                  {student.studentName}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-4">
            <label htmlFor="studentId" className="form-label">Student ID</label>
            <input
              type="text"
              className="form-control"
              id="studentId"
              value={selectedStudent?._id || ''}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="roomNumber" className="form-label">Room Number</label>
            <select
              className="form-select"
              id="roomNumber"
              value={selectedRoomId}
              onChange={handleRoomChange}
              required
            >
              <option value="" disabled>Select a room</option>
              {rooms.map((room) => (
                <option key={room._id} value={room._id}>
                  {room.roomNumber}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="roomType" className="form-label">Room Type</label>
            <input
              type="text"
              className="form-control"
              id="roomType"
              value={selectedRoom?.roomType || ''}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="roomPrice" className="form-label">Room Price/Month</label>
            <input
              type="text"
              className="form-control"
              id="roomPrice"
              value={selectedRoom?.monthlyRent || ''}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="bookingDate" className="form-label">Booking Date</label>
            <input
              type="date"
              className="form-control"
              id="bookingDate"
              value={booking?.bookingDate || ''}
              required
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="startDate" className="form-label">Start Date</label>
            <input
              type="date"
              className="form-control"
              id="startDate"
              value={booking?.startDate || ''}
              required
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="endDate" className="form-label">End Date</label>
            <input
              type="date"
              className="form-control"
              id="endDate"
              value={booking?.endDate || ''}
              required
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="totalMonths" className="form-label">No. of Months</label>
            <select
              className="form-select"
              id="totalMonths"
              value={totalPayment / (selectedRoom?.monthlyRent || 1)}
              onChange={handleTotalPaymentChange}
              required
            >
              <option value="" disabled>Select No. of months</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="advancedPayment" className="form-label">Advanced Payment</label>
            <input
              type="number"
              className="form-control"
              id="advancedPayment"
              value={advancedPayment}
              onChange={handleAdvancedPaymentChange}
              onBlur={handleTotalPaymentChange}
              required
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="remainingAmount" className="form-label">Remaining Amount</label>
            <input
              type="text"
              className="form-control"
              id="remainingAmount"
              value={remainingAmount}
              readOnly
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="totalPayment" className="form-label">Total Amount</label>
            <input
              type="text"
              className="form-control"
              id="totalPayment"
              value={totalPayment}
              readOnly
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="paymentMode" className="form-label">Payment Mode</label>
            <input
              type="text"
              className="form-control"
              id="paymentMode"
              value={paymentMode || ''}
              onChange={(e) => setPaymentMode(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="paymentStatus" className="form-label">Payment Status</label>
            <input
              type="text"
              className="form-control"
              id="paymentStatus"
              value={paymentStatus || ''}
              onChange={(e) => setPaymentStatus(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="notes" className="form-label">Notes</label>
            <textarea
              className="form-control"
              id="notes"
              value={notes || ''}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-12">
            <button type="submit" className="btn btn-primary w-100">
              Update Booking
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdateBookings;
