import React, { useState } from 'react'
function CreateWorkers() {
  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-center">Add Worker</h2>
      <form id="workerForm">
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="fullName" className="form-label">Full Name</label>
            <input type="text" className="form-control" id="fullName" placeholder="Enter full name" required />
          </div>
          <div className="col-md-4">
            <label htmlFor="phone" className="form-label">Phone</label>
            <input type="tel" className="form-control" id="phone" placeholder="Enter phone number" required />
          </div>
          <div className="col-md-4">
            <label htmlFor="address" className="form-label">Address</label>
            <input type="text" className="form-control" id="address" placeholder="Enter address" required />
          </div>
        </div>

        <div id="housekeepingFields" className="hidden">
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="shift" className="form-label">Shift</label>
              <select className="form-select" id="shift">
                <option value="">Select shift</option>
                <option value="morning">Morning</option>
                <option value="evening">Evening</option>
                <option value="night">Night</option>
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="floor" className="form-label">Assigned Floor</label>
              <input type="number" className="form-control" id="floor" placeholder="Enter assigned floor" />
            </div>
            <div className="col-md-4">
              <label htmlFor="workerType" className="form-label">Worker Type</label>
              <select className="form-select" id="workerType" required>
                <option value="">Select worker type</option>
                <option value="housekeeping">Housekeeping</option>
                <option value="kitchen">Kitchen Staff</option>
                <option value="security">Security</option>
                <option value="administration">Administration</option>
                <option value="maintenance">Maintenance</option>
              </select>
            </div>
          </div>
        </div>

        <div id="kitchenFields" className="hidden">
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="speciality" className="form-label">Speciality</label>
              <input type="text" className="form-control" id="speciality" placeholder="e.g., Chef, Helper" />
            </div>
            <div className="col-md-4">
              <label htmlFor="experience" className="form-label">Years of Experience</label>
              <input type="number" className="form-control" id="experience" placeholder="Enter years of experience" />
            </div>
            <div className="col-md-4">
              <label htmlFor="weaponCertified" className="form-label">Weapon Certified</label>
              <select className="form-select" id="weaponCertified">
                <option value="">Select option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
        </div>

        <div id="securityFields" className="hidden">
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="shiftTiming" className="form-label">Shift Timing</label>
              <input type="text" className="form-control" id="shiftTiming" placeholder="e.g., 8 AM - 4 PM" />
            </div>
            <div className="col-md-4">
              <label htmlFor="designation" className="form-label">Designation</label>
              <input type="text" className="form-control" id="designation" placeholder="Enter designation" />
            </div>
            <div className="col-md-4">
              <label htmlFor="department" className="form-label">Department</label>
              <input type="text" className="form-control" id="department" placeholder="Enter department" />
            </div>
          </div>
        </div>

        <div id="maintenanceFields" className="hidden">
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="specialization" className="form-label">Specialization</label>
              <input type="text" className="form-control" id="specialization" placeholder="e.g., Electrical, Plumbing" />
            </div>
            <div className="col-md-4">
              <label htmlFor="tools" className="form-label">Tools Provided</label>
              <input type="text" className="form-control" id="tools" placeholder="List tools provided" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary text-white">Submit</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateWorkers