import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const logout = () => {
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("token");
  localStorage.removeItem("role");
  localStorage.clear(); 
  window.location.href = "/login"; 
};

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');

  if (!isAuthenticated || !token) {
    return <Navigate to="/login" />;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      logout();
      return null
    }
  } catch (error) {
    console.error("Invalid token:", error);
    logout();
    return null;
  }

  return children;
};

export default ProtectedRoute;
export { logout };
