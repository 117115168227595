import React, { useEffect, useState } from 'react';
import { FaTrashCan } from 'react-icons/fa6';
import { MdOutlineKeyboardAlt, MdOutlineBorderColor } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteBooking, getBookings } from '../reducers/BookingSlice';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import { toast } from 'react-toastify';


const Bookings = () => {
  const dispatch = useDispatch();
  const {
    bookings,
    currentPage,
    totalPages,
    totalBookings,
    limit,
    loading
  } = useSelector((state) => state.bookings);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false)
  const [bookingToDelete, setBookingToDelete] = useState(null)



  useEffect(() => {
    dispatch(getBookings({ page: currentPage, searchQuery }));
  }, [dispatch, currentPage, searchQuery]);

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   // Reset to first page on search
  //   dispatch(getBookings({ page: 1, searchQuery }));
  // };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      dispatch(getBookings({ page: newPage, searchQuery }));
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setBookingToDelete(null)
  }
  const handleDeleteClick = (id) => {
    setBookingToDelete(id)
    setOpenDialog(true)
  }

  const handleConfirmDelete = async () => {
    if (bookingToDelete) {
      try {
        await dispatch(deleteBooking(bookingToDelete)).unwrap();
        toast.success('Booking deleted successfully!');
        await dispatch(getBookings({ page: currentPage, searchQuery: '' }));
      } catch (error) {
        console.error('Error deleting student:', error);
        toast.error('Failed to delete the booking. Please try again.');
      } finally {
        setOpenDialog(false);
        setBookingToDelete(null);
      }
    }
  };


  return (
    <>
      <div className="table-header d-flex justify-content-between mt-3 p-3">
        <div className="total-count">
          <h6>Total Bookings ({totalBookings})</h6>
        </div>
        <div className="add-btn">
          <Link to="/bookings/add-bookings">
            <button className="btn btn-primary text-white">Add Bookings</button>
          </Link>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Sr #</th>
            <th scope="col">Student Name</th>
            <th scope="col">Room No</th>
            <th scope="col">Floor No</th>
            <th scope="col">Room Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="6" className="text-center">Loading...</td>
            </tr>
          ) : bookings.length > 0 ? (
            bookings.map((booking, index) => (
              <tr key={booking.id}>
                <th scope="row">{(currentPage - 1) * limit + index + 1}</th>
                <td>{booking.selectedStudent}</td>
                <td>{booking.selectedRoom}</td>
                <td>{booking.floorNo}</td>
                <td>{booking.roomStatus}</td>
                <td className="action-flex">
                  <Link to={`/view-booking/${booking.id}`}>
                    <MdOutlineKeyboardAlt className="keyboard" />
                  </Link>
                  <Link to={`/bookings/edit-booking/${booking._id}`}>
                    <MdOutlineBorderColor className="edit" />
                  </Link>
                  <FaTrashCan className='delete' onClick={() => handleDeleteClick(booking._id)} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">No bookings found.</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="pagination-1 d-flex justify-content-center gap-3 align-items-center">
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>


      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this student?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Bookings;
