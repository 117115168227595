import React, { useEffect, useState } from 'react';
import { FaTrashCan } from 'react-icons/fa6';
import { MdOutlineKeyboardAlt, MdOutlineBorderColor } from "react-icons/md";
import { Link } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRoom, fetchRooms, setCurrentPage } from '../reducers/RoomSlice';
import { toast } from 'react-toastify';


const Rooms = () => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [roomToDelete, setRoomToDelete] = useState(null);


  const { rooms, totalPages, totalRooms, currentPage, status } = useSelector(state => state.rooms);

  useEffect(() => {
    dispatch(fetchRooms({ page: currentPage, searchQuery: '' }));
  }, [dispatch, currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      dispatch(setCurrentPage(newPage));
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setRoomToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (roomToDelete) {
      try {
        await dispatch(deleteRoom(roomToDelete)).unwrap();
        toast.success('Room deleted successfully!');
        await dispatch(fetchRooms({ page: currentPage, searchQuery: '' }));
      } catch (error) {
        toast.error('Failed to delete the room. Please try again.');
      } finally {
        setOpenDialog(false);
        setRoomToDelete(null);
      }
    }
  };
  const handleDeleteClick = (roomId) => {
    setRoomToDelete(roomId)
    setOpenDialog(true)
  }

  return (
    <>
      <div className="table-header d-flex justify-content-between align-items-center mt-3 p-3">
        <div className="total-count">
          <h6 className='mb-0'>Total Rooms ({totalRooms})</h6>
        </div>
        <div className="add-btn">
          <Link to="/rooms/add-rooms">
            <button className="btn btn-primary text-white">Add Room</button>
          </Link>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr #</th>
              <th scope="col">Room No</th>
              <th scope="col">Floor No</th>
              <th scope="col">Total Capacity</th>
              <th scope="col">Remaining Capacity</th>
              <th scope="col">Room Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rooms?.length > 0 ? (
              rooms.map((room, index) => {
                let statusColor = '';
                switch (room.roomStatus.toLowerCase()) {
                  case 'available':
                    statusColor = 'green';
                    break;
                  case 'occupied':
                    statusColor = 'red';
                    break;
                  case 'maintenance':
                    statusColor = 'orange';
                    break;
                  default:
                    statusColor = 'gray';
                }
                return (
                  <tr key={room._id}>
                    <th scope="row">{(currentPage - 1) * 10 + index + 1}</th>
                    <td>{room.roomNumber}</td>
                    <td>{room.floorNumber}</td>
                    <td>{room.capacity}</td>
                    <td>{room.remainingCapacity}</td>
                    <td style={{ textTransform: 'capitalize', color: statusColor }}>
                      {room.roomStatus}
                    </td>
                    <td className="action-flex">
                      <Link to={`/view-room/${room._id}`}>
                        <MdOutlineKeyboardAlt className="keyboard" />
                      </Link>
                      <Link to={`/rooms/edit-room/${room._id}`}>
                        <MdOutlineBorderColor className="edit" />
                      </Link>
                      <FaTrashCan className='delete' onClick={() => handleDeleteClick(room._id)} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  {status === 'loading' ? 'Loading...' : 'No rooms available'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="pagination-1 d-flex justify-content-center gap-3 align-items-center">
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <div className="page-info">
          Page {currentPage} of {totalPages}
        </div>
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this room?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleConfirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Rooms;
