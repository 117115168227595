import axiosInstance from './AxiosInstance';
import apiRoutes from '../Constant';

const service = {
  //Auth
  register: (data) => axiosInstance.post(`${apiRoutes.auth}/register`, data),
  login: (data) => axiosInstance.post(`${apiRoutes.auth}/login`, data),
  getAuthById: (id, config) => axiosInstance.get(`${apiRoutes.auth}/get-auth/${id}`, config),
  updateAuth: (id, data, config) => axiosInstance.put(`${apiRoutes.auth}/update-auth/${id}`, data, config),
  deleteAuth: (id, config) => axiosInstance.delete(`${apiRoutes.auth}/delete-auth/${id}`, config),

  // Students
  createStudent: (data, config) => axiosInstance.post(`${apiRoutes.students}/add-student`, data, config),
  getStudents: (params) => axiosInstance.get(`${apiRoutes.students}/get-students`, { params }),
  getAllStudents: (config) => axiosInstance.get(`${apiRoutes.students}/get-all-students`, config),
  getStudentById: (id, config) => axiosInstance.get(`${apiRoutes.students}/get-student/${id}`, config),
  updateStudent: (id, data, config) => axiosInstance.put(`${apiRoutes.students}/update-student/${id}`, data, config),
  deleteStudent: (id, config) => axiosInstance.delete(`${apiRoutes.students}/delete-student/${id}`, config),

  // Rooms
  createRoom: (data, config) => axiosInstance.post(`${apiRoutes.rooms}/add-room`, data, config),
  getRooms: (params) => axiosInstance.get(`${apiRoutes.rooms}/get-rooms`, { params }),
  getAllRooms: (config) => axiosInstance.get(`${apiRoutes.rooms}/get-all-rooms`, config),
  getRoomById: (id, config) => axiosInstance.get(`${apiRoutes.rooms}/get-room/${id}`, config),
  updateRoom: (id, data, config) => axiosInstance.put(`${apiRoutes.rooms}/update-room/${id}`, data, config),
  deleteRoom: (id, config) => axiosInstance.delete(`${apiRoutes.rooms}/delete-room/${id}`, config),

  // Bookings
  createBooking: (data, config) => axiosInstance.post(`${apiRoutes.bookings}/add-booking`, data, config),
  getBookings: (params) => axiosInstance.get(`${apiRoutes.bookings}/get-bookings`, { params }),
  getBookingById: (id, config) => axiosInstance.get(`${apiRoutes.bookings}/get-booking/${id}`, config),
  updateBooking: (id, data, config) => axiosInstance.put(`${apiRoutes.bookings}/update-booking/${id}`, data, config),
  deleteBooking: (id, config) => axiosInstance.delete(`${apiRoutes.bookings}/delete-booking/${id}`, config),
};

export default service;
