import React from 'react'
import '../assets/css/Profile.css';
import profileimage from '../assets/images/logo.jpg';
import { Link } from 'react-router-dom'
function Profile() {
  return (
    <>
      <div className='cricketwinbet-profile-main-bg'>
        <div className='cricketwinbet-profile-breadcrumb-bg'>
          <p>Profile Settings</p>
        </div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-4 mb-5'>
              <div className='cricketwinbet-profile-card-bg'>
                <div className='cricketwinbet-profile-card-image'>
                  <img src={profileimage} alt='Profile Image' />
                </div>
                <div className='profile-card-name'>
                  <p>strdxc321</p>
                </div>
                <div className='profile-card-email'>
                  <h2>UUID:</h2>
                  <p>haseebramzan521@gmail.com</p>
                </div>
                <div className='profile-card-reset-wrapper'>
                  <div className='profile-card-reset-btn'>
                    <Link to={'/email'}> Reset Password</Link>
                  </div>

                  <div className='profile-card-game-changer-btn'>
                    <Link to={'/game'}>Change Gamertag</Link>
                  </div>
                </div>
                <div className='profile-card-join-date'>
                  <p>Joined December 19th, 2022</p>
                  <span>Last Login on</span>
                </div>
              </div>
            </div>
            <div className='col-md-8'>
              <div className='cricketwinbet-detail-form-bg'>
                <div className='cricketwinbet-detail-heading'>
                  <strong>About You</strong>
                </div>
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <div className='cricketwinbet-detail-form-fields'>
                      <label>User Name</label>
                      <br></br>
                      <input type='text' placeholder='User Name' />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='cricketwinbet-detail-form-fields'>
                      <label>Email</label>
                      <br></br>
                      <input type='email' placeholder='Email' />
                    </div>
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-md-6'>
                    <div className='cricketwinbet-detail-form-fields'>
                      <label>User Name</label>
                      <br></br>
                      <input type='text' placeholder='User Name' />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='cricketwinbet-detail-form-fields'>
                      <label>Email</label>
                      <br></br>
                      <input type='email' placeholder='Email' />
                    </div>
                  </div>
                </div>
                <div className='cricketwinbet-detail-save-btn pt-3 d-flex justify-content-center'>
                  <Link>Edit </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile