import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createStudent } from '../reducers/StudentSlice';
import { useNavigate } from 'react-router';

function AddStudent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    studentName: '',
    fatherName: '',
    email: '',
    dob: '',
    studentPic: null,
    gender: '',
    cnic: '',
    city: '',
    contactNumber: '',
    currentAddress: '',
    permanentAddress: '',
    emergencyContact: '',
    department: '',
    currentYear: '',
    semester: '',
    bloodGroup: '',
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'file' ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitData = new FormData();
    Object.keys(formData).forEach((key) => {
      submitData.append(key, formData[key]);
    });

    console.log('Submitting Data:');
    console.log('FormData:', formData);

    try {
      const response = await dispatch(createStudent(submitData));

      if (response) {
        toast.success('Student has been added!');
        navigate('/students');
        setFormData({
          studentName: '',
          fatherName: '',
          email: '',
          dob: '',
          studentPic: null,
          gender: '',
          cnic: '',
          city: '',
          contactNumber: '',
          currentAddress: '',
          permanentAddress: '',
          emergencyContact: '',
          department: '',
          currentYear: '',
          semester: '',
          bloodGroup: '',
        });
      } else {
        toast.error('An error occurred while adding the student.');
        console.error('Error:', response.error);
      }
    } catch (error) {
      toast.error('An error occurred while adding the student.');
      console.error('Error:', error);
    }
  };
  return (
    <div className="container mt-5 text-white">
      <h2 className="text-center mb-4">Student Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="studentName" className="form-label">Student Name</label>
            <input
              type="text"
              className="form-control"
              id="studentName"
              name="studentName"
              placeholder="Enter Student Name"
              onChange={handleChange}
              value={formData.studentName}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="fatherName" className="form-label">Father Name</label>
            <input
              type="text"
              className="form-control"
              id="fatherName"
              name="fatherName"
              placeholder="Enter Father Name"
              onChange={handleChange}
              value={formData.fatherName}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter email"
              onChange={handleChange}
              value={formData.email}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="dob" className="form-label">Date of Birth</label>
            <input
              type="date"
              className="form-control"
              id="dob"
              name="dob"
              onChange={handleChange}
              value={formData.dob}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="studentPic" className="form-label">Student Picture</label>
            <input
              type="file"
              className="form-control"
              id="studentPic"
              name="studentPic"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="gender" className="form-label">Gender</label>
            <select
              className="form-select"
              id="gender"
              name="gender"
              onChange={handleChange}
              value={formData.gender}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="cnic" className="form-label"> Student CNIC</label>
            <input
              type="text"
              className="form-control"
              id="cnic"
              name="cnic"
              placeholder="Enter CNIC"
              onChange={handleChange}
              value={formData.cnic}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="city" className="form-label">City</label>
            <input
              type="text"
              className="form-control"
              id="city"
              name="city"
              placeholder="Enter city"
              onChange={handleChange}
              value={formData.city}
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="contactNumber" className="form-label">Contact Number</label>
            <input
              type="tel"
              className="form-control"
              id="contactNumber"
              name="contactNumber"
              placeholder="Enter contact number"
              onChange={handleChange}
              value={formData.contactNumber}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="currentAddress" className="form-label">Current Address</label>
            <input
              type="text"
              className="form-control"
              id="currentAddress"
              name="currentAddress"
              placeholder="Enter current address"
              onChange={handleChange}
              value={formData.currentAddress}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="permanentAddress" className="form-label">Permanent Address</label>
            <input
              type="text"
              className="form-control"
              id="permanentAddress"
              name="permanentAddress"
              placeholder="Enter permanent address"
              onChange={handleChange}
              value={formData.permanentAddress}
              required
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="emergencyContact" className="form-label">Emergency Contact Number</label>
            <input
              type="tel"
              className="form-control"
              id="emergencyContact"
              name="emergencyContact"
              placeholder="Enter emergency contact number"
              onChange={handleChange}
              value={formData.emergencyContact}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="department" className="form-label">Department</label>
            <select
              className="form-control"
              id="department"
              name="department"
              onChange={handleChange}
              value={formData.department}
            >
              <option value="">Select a department</option>
              <option value="Computer Science">Computer Science</option>
              <option value="Electrical Engineering">Electrical Engineering</option>
              <option value="Mechanical Engineering">Mechanical Engineering</option>
              <option value="Civil Engineering">Civil Engineering</option>
              <option value="Business Administration">Business Administration</option>
              <option value="Mathematics">Mathematics</option>
              <option value="Physics">Physics</option>
              <option value="Chemistry">Chemistry</option>
              <option value="Biology">Biology</option>
              <option value="Economics">Economics</option>
              <option value="Psychology">Psychology</option>
              <option value="History">History</option>
              <option value="English Literature">English Literature</option>
              <option value="Philosophy">Philosophy</option>
              <option value="Law">Law</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="currentYear" className="form-label">Current Year</label>
            <input
              type="number"
              className="form-control"
              id="currentYear"
              name="currentYear"
              placeholder="Enter current year"
              onChange={handleChange}
              value={formData.currentYear}
            />
          </div>
        </div>

        <div className="row g-3 mb-3">
          <div className="col-md-4">
            <label htmlFor="semester" className="form-label">Semester</label>
            <input
              type="number"
              className="form-control"
              id="semester"
              name="semester"
              placeholder="Enter semester"
              onChange={handleChange}
              value={formData.semester}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="bloodGroup" className="form-label">Blood Group</label>
            <input
              type="text"
              className="form-control"
              id="bloodGroup"
              name="bloodGroup"
              placeholder="Enter blood group"
              onChange={handleChange}
              value={formData.bloodGroup}
            />
          </div>
        </div>

        <div className="text-center d-flex justify-content-end">
          <button type="submit" className="btn btn-primary text-white">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddStudent;
