import React, { useState } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
function AddStaff() {
  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-center">Add Staff</h2>
      <form>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="fullName" className="form-label">Full Name</label>
            <input type="text" className="form-control" id="fullName" placeholder="Enter full name" required />
          </div>
          <div className="col-md-4">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" placeholder="Enter email" required />
          </div>
          <div className="col-md-4">
            <label htmlFor="phone" className="form-label">Phone</label>
            <input type="tel" className="form-control" id="phone" placeholder="Enter phone number" required />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="dob" className="form-label">Date of Birth</label>
            <input type="date" className="form-control" id="dob" required />
          </div>
          <div className="col-md-4">
            <label htmlFor="designation" className="form-label">Designation</label>
            <input type="text" className="form-control" id="designation" placeholder="Enter designation" required />
          </div>
          <div className="col-md-4">
            <label htmlFor="joinDate" className="form-label">Joining Date</label>
            <input type="date" className="form-control" id="joinDate" required />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="gender" className="form-label">Gender</label>
            <select className="form-select" id="gender" >
              <option value="">Select gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="salary" className="form-label">Salary</label>
            <input type="number" className="form-control" id="salary" placeholder="Enter salary" required />
          </div>
          <div className="col-md-4">
            <label htmlFor="address" className="form-label">Address</label>
            <input type="text" className="form-control" id="address" placeholder="Enter address" required />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="aadhar" className="form-label">Aadhar/ID Number</label>
            <input type="text" className="form-control" id="aadhar" placeholder="Enter ID number" required />
          </div>
          <div className="col-md-4">
            <label htmlFor="department" className="form-label">Department</label>
            <input type="text" className="form-control" id="department" placeholder="Enter department" required />
          </div>
          <div className="col-md-4">
            <label htmlFor="photo" className="form-label">Photo</label>
            <input type="file" className="form-control" id="photo" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary text-white">Add Staff</button>
          </div>
        </div>
      </form>
    </div>

  )
}

export default AddStaff