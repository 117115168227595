import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import service from '../api/ApiRoutes';

const initialState = {
    rooms: [],
    room: null,
    totalRooms: 0,
    totalPages: 0,
    currentPage: 1,
    status: 'idle',
    error: null,
    successMessage: '',
    searchQuery: '',
};

// Create a new room
export const createRoom = createAsyncThunk(
    'rooms/createRoom',
    async (roomData, thunkAPI) => {
        try {
            const response = await service.createRoom(roomData);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Fetch all rooms with search, pagination, and filtering
export const fetchRooms = createAsyncThunk(
    'rooms/fetchRooms',
    async ({ page = 1, searchQuery = '' }, thunkAPI) => {
        try {
            const response = await service.getRooms({ page, searchQuery });
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || 'Failed to fetch rooms');
        }
    }
);
// Get All Rooms
export const fetchAllRooms = createAsyncThunk(
    'rooms/fetchAllRooms',
    async (config, thunkAPI) => {
        try {
            const response = await service.getAllRooms(config);
            return response.data;
        } catch (error) {
            console.error('API error:', error);
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


// Fetch a single room by ID
export const fetchRoomById = createAsyncThunk(
    'rooms/fetchRoomById',
    async (roomId, thunkAPI) => {
        try {
            const response = await service.getRoomById(roomId);
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
        }
    }
);

// Update a room
export const updateRoom = createAsyncThunk(
    'rooms/updateRoom',
    async ({ id, roomData, config }, thunkAPI) => {
        try {
            const response = await service.updateRoom(id, roomData, config);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Delete a room
export const deleteRoom = createAsyncThunk(
    'rooms/deleteRoom',
    async (roomId, thunkAPI) => {
        try {
            await service.deleteRoom(roomId);
            return roomId;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Slice
const roomSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        resetState(state) {
            state.error = null;
            state.successMessage = '';
            state.status = 'idle';
        },
        setSearchQuery(state, action) {
            state.searchQuery = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        removeRoom(state, action) {
            state.rooms = state.rooms.filter(room => room._id !== action.payload);
        },
        updateRoomData(state, action) {
            const index = state.rooms.findIndex((room) => room._id === action.payload._id);
            if (index !== -1) {
                state.rooms[index] = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // Create room
            .addCase(createRoom.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createRoom.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.rooms.push(action.payload);
                state.successMessage = 'Room created successfully!';
            })
            .addCase(createRoom.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Fetch all rooms with pagination and search
            .addCase(fetchRooms.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRooms.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { rooms, totalPages, totalRooms, currentPage } = action.payload;
                state.rooms = rooms;
                state.totalPages = totalPages;
                state.totalRooms = totalRooms;
                state.currentPage = currentPage;
            })
            .addCase(fetchRooms.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Fetch all rooms
            .addCase(fetchAllRooms.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchAllRooms.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.rooms = Array.isArray(action.payload)
                    ? action.payload
                    : action.payload?.rooms || [];
            })
            .addCase(fetchAllRooms.rejected, (state, action) => {
                state.status = 'failed';
                console.error('fetchAllRooms rejected error:', action.payload);
                state.error = action.payload?.message || 'Failed to fetch rooms';
            })

            // Fetch single room by ID
            .addCase(fetchRoomById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRoomById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.room = action.payload;
            })
            .addCase(fetchRoomById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Update room
            .addCase(updateRoom.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateRoom.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.rooms.findIndex((r) => r._id === action.payload._id);
                if (index !== -1) {
                    state.rooms[index] = action.payload;
                }
                state.successMessage = 'Room updated successfully!';
            })
            .addCase(updateRoom.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Delete room
            .addCase(deleteRoom.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteRoom.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.rooms = state.rooms.filter((r) => r._id !== action.payload);
                state.successMessage = 'Room deleted successfully!';
            })
            .addCase(deleteRoom.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { resetState, setSearchQuery, setCurrentPage, removeRoom } = roomSlice.actions;
export default roomSlice.reducer;
