import { configureStore } from '@reduxjs/toolkit';
import StudentReducer from '../reducers/StudentSlice';
import RoomReducer from '../reducers/RoomSlice';
import BookingReducer from '../reducers/BookingSlice';


export const store = configureStore({
    reducer: {
        students: StudentReducer,
        rooms: RoomReducer,
        bookings: BookingReducer,
    },
});